import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  Box,
  CardContent,
  Grid,
  TextField,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Menu from "./Menu"; // Assuming you have the same Menu component

const ProfileDetails = () => {
  const [profile, setProfile] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    birthday: "",
    cf_gender: "",
    cf_id_number: "",
    cf_race: "",
    cf_disability: "",
    cf_nationality: "",
    mailingstreet: "",
    cf_home_language: "",
    assigned_user_id: ""
  }); // User's profile data

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let tempErrors = {};
    tempErrors.firstname = profile.firstname ? "" : "This field is required.";
    tempErrors.lastname = profile.lastname ? "" : "This field is required.";
    tempErrors.mobile = profile.mobile ? "" : "This field is required.";
    tempErrors.birthday = profile.birthday ? "" : "This field is required.";
    tempErrors.cf_gender = profile.cf_gender ? "" : "This field is required.";
    tempErrors.cf_id_number = profile.cf_id_number
      ? ""
      : "This field is required.";
    tempErrors.cf_race = profile.cf_race ? "" : "This field is required.";
    tempErrors.cf_disability = profile.cf_disability
      ? ""
      : "This field is required.";
    tempErrors.cf_nationality = profile.cf_nationality
      ? ""
      : "This field is required.";
    tempErrors.cf_home_language = profile.cf_home_language
      ? ""
      : "This field is required.";

    setErrors({ ...tempErrors });
    return Object.values(tempErrors).every((x) => x === "");
  };
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleCancel = () => {
    navigate("/dashboard");
  };
  const handleLogout = () => {
    Cookies.remove("contactid");
    navigate("/");
  };

  // Fetch user profile data
  // Fetch user profile data
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const contactId = Cookies.get("contactid");

        if (!contactId) {
          console.error("Contact ID is invalid.");
          navigate("/"); // Redirect to landing page or login
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_SAFEHUB_BMS_URL}/webservices/getUser/S@f3Hub123@/${contactId}`
        );

        // console.log("Response:", response);

        if (response.data && response.data.length > 0) {
          console.log(response.data[0]);
          setProfile(response.data[0]);
        } else {
          console.error("No valid user details found.");
          navigate("/");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        navigate("/");
      }
    };

    fetchProfile();
  }, [navigate]); // Adding navigate as a dependency

  useEffect(() => {
    if (profile.birthday) {
      const formattedDate = new Date(profile.birthday).toISOString().split('T')[0];
      setProfile((prevProfile) => ({
        ...prevProfile,
        birthday: formattedDate,
      }));
    }
  }, [profile.birthday]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      [name]: value,
    });
    // Clear errors
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      // Construct the payload with the updated profile data and the contact ID
      const payload = {
        module: "Contacts",
        data: profile,
        // Assuming the contact ID is stored in the cookies
      };
      payload.data.id = Cookies.get("contactid");
      // payload.data.assigned_user_id = "19x1";
      payload.data.cf_profile_updated = "1";
      try {
        // Make the POST request to the `/update` endpoint
        const response = await axios.post(
          `${process.env.REACT_APP_EXPRESS_SERVER_URL}/update`,
          payload
        );

        // Handle the response accordingly
        if (response) {
          console.log("Profile updated successfully:", response.data);
          // Navigate to the dashboard or show a success message
          navigate("/dashboard");
        } else {
          // Handle any errors or unsuccessful update attempts
          console.error("Profile update failed:", response.data.message);
          // Optionally set an error message state to display to the user
        }
      } catch (error) {
        console.error("An error occurred while updating the profile:", error);
        // Optionally set an error message state to display to the user
      }
    }
  };

  return (
    <div>
      {/* AppBar */}
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Profile Details
          </Typography>
        </Toolbar>
      </AppBar>
      {/* Drawer */}
      <Menu
        openDrawer={openDrawer}
        handleDrawerToggle={handleDrawerToggle}
        handleLogout={handleLogout}
      />
      <br></br>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6">Personal Details</Typography>
              <TextField
                fullWidth
                margin="normal"
                name="firstname"
                label="First Name"
                variant="outlined"
                value={profile.firstname}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                margin="normal"
                name="lastname"
                label="Last Name"
                variant="outlined"
                value={profile.lastname}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                margin="normal"
                name="email"
                label="Email"
                variant="outlined"
                value={profile.email}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                margin="normal"
                name="mobile"
                label="Mobile"
                variant="outlined"
                value={profile.mobile}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                type="date"
                margin="normal"
                name="birthday"
                label="Birthday"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={profile.birthday}
                onChange={handleChange}
                required
              />
              <Box mt={2} width="100%">
                <FormControl fullWidth error={Boolean(errors.cf_gender)}>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    name="cf_gender"
                    value={profile.cf_gender}
                    onChange={handleChange}
                    label="Gender"
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Prefer to self identify">Prefer to self identify</MenuItem>
                  </Select>
                  {errors.cf_gender && (
                    <FormHelperText>{errors.cf_gender}</FormHelperText>
                  )}
                </FormControl>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6">Additional Details</Typography>
              <TextField
                fullWidth
                margin="normal"
                name="cf_id_number"
                label="ID Number"
                variant="outlined"
                value={profile.cf_id_number}
                onChange={handleChange}
                required
              />
              <Box mt={2} width="100%">
                <FormControl fullWidth error={Boolean(errors.cf_home_language)}>
                  <InputLabel>Home Language</InputLabel>
                  <Select
                    name="cf_home_language"
                    value={profile.cf_home_language}
                    onChange={handleChange}
                    label="Home Language"
                  >
                    {/* List all South African languages + German */}
                    <MenuItem value="Afrikaans">Afrikaans</MenuItem>
                    <MenuItem value="English">English</MenuItem>
                    <MenuItem value="Ndebele">Ndebele</MenuItem>
                    <MenuItem value="Northern Sotho">Northern Sotho</MenuItem>
                    <MenuItem value="Sotho">Sotho</MenuItem>
                    <MenuItem value="Swazi">Swazi</MenuItem>
                    <MenuItem value="Tsonga">Tsonga</MenuItem>
                    <MenuItem value="Tswana">Tswana</MenuItem>
                    <MenuItem value="Venda">Venda</MenuItem>
                    <MenuItem value="Xhosa">Xhosa</MenuItem>
                    <MenuItem value="Zulu">Zulu</MenuItem>
                    <MenuItem value="German">German</MenuItem>
                  </Select>
                  {errors.cf_home_language && (
                    <FormHelperText>{errors.cf_home_language}</FormHelperText>
                  )}
                </FormControl>
              </Box>

              {/* Race */}
              <Box mt={2} width="100%">
                <FormControl fullWidth error={Boolean(errors.cf_race)}>
                  <InputLabel>Race</InputLabel>
                  <Select
                    name="cf_race"
                    value={profile.cf_race}
                    onChange={handleChange}
                    label="Race"
                  >
                    <MenuItem value="African">African</MenuItem>
                    <MenuItem value="Coloured">Coloured</MenuItem>
                    <MenuItem value="Indian">Indian</MenuItem>
                    <MenuItem value="White">White</MenuItem>
                  </Select>
                  {errors.cf_race && (
                    <FormHelperText>{errors.cf_race}</FormHelperText>
                  )}
                </FormControl>
              </Box>

              {/* Disability */}
              <Box mt={2} width="100%">
                <FormControl fullWidth error={Boolean(errors.cf_disability)}>
                  <InputLabel>Disability</InputLabel>
                  <Select
                    name="cf_disability"
                    value={profile.cf_disability}
                    onChange={handleChange}
                    label="Disability"
                  >
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value="Sight (even with glasses)">Sight (even with glasses)</MenuItem>
                    <MenuItem value="Hearing (even with hearing aid)">Hearing (even with hearing aid)</MenuItem>
                    <MenuItem value="Communication (talking Listening)">Communication (talking Listening)</MenuItem>
                    <MenuItem value="Physical (Moving, Standing grasping)">Physical (Moving, Standing grasping)</MenuItem>
                    <MenuItem value="Intellectual (difficulties in learning)">Intellectual (difficulties in learning)</MenuItem>
                    <MenuItem value="Emotional (behavioral or psychological)">Emotional (behavioral or psychological)</MenuItem>
                    <MenuItem value="Multiple">Multiple</MenuItem>
                    <MenuItem value="Disability unspecified">Disability unspecified</MenuItem>
                  </Select>
                  {errors.cf_disability && (
                    <FormHelperText>{errors.cf_disability}</FormHelperText>
                  )}
                </FormControl>
              </Box>

              {/* Nationality */}
              <Box mt={2} width="100%">
                <FormControl fullWidth error={Boolean(errors.cf_nationality)}>
                  <InputLabel>Nationality</InputLabel>
                  <Select
                    name="cf_nationality"
                    value={profile.cf_nationality}
                    onChange={handleChange}
                    label="Nationality"
                  >
                    <MenuItem value="South African">South African</MenuItem>
                    <MenuItem value="German">German</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  {errors.cf_nationality && (
                    <FormHelperText>{errors.cf_nationality}</FormHelperText>
                  )}
                </FormControl>
              </Box>
              <TextField
                fullWidth
                margin="normal"
                name="mailingstreet"
                label="Current Address"
                variant="outlined"
                value={profile.mailingstreet}
                onChange={handleChange}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: "10px" }}
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfileDetails;
