import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography,Container, Paper } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from './Menu';  // Importing Menu Component
import Cookies from 'js-cookie';

const Support = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleLogout = () => {
    Cookies.remove("contactid");
    // Redirects user to landing page or login page
  };

  

  return (
    <div>
      {/* AppBar */}
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Get Support
          </Typography>
          {/* <IconButton color="inherit" onClick={handleLogout}>
            <ExitToAppIcon />
          </IconButton> */}
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <Menu openDrawer={openDrawer} handleDrawerToggle={handleDrawerToggle} handleLogout={handleLogout} />

      {/* Support Message */}
      <Container style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Paper elevation={3} style={{ padding: '15px' }}>
          <Typography variant="h4">
            Need Assistance?
          </Typography>
          <Typography variant="body1" style={{ marginTop: '20px' }}>
            If you require any support or have any queries, please don't hesitate to get in touch with your DLAB / Hub manager for assistance. We are here to help you with any questions or concerns you might have.
          </Typography>
        </Paper>
      </Container>
    </div>
  );
};

export default Support;
