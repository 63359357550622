import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  MobileStepper,
} from "@mui/material";
import Cookies from "js-cookie"; // Import the js-cookie library
const LandingPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Sample logic to check if user is authenticated
    // const isAuthenticated = localStorage.getItem('contactid'); // Adjust as per your logic
    const isAuthenticated = Cookies.get("contactid"); // Adjust as per your logic

    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const [banners, setBanners] = useState([]);
  const [whatWeDo, setWhatWeDo] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const [sites, setSites] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SAFEHUB_BMS_URL2}/webservices/getPortalBanners/S@f3Hub123@`
        );
        const data = await response.json();

        // Check if we received data and extract banners
        if (data && data.length > 0) {
          const bannersData = data[0];
          const fullBannerURLs = ["banner1", "banner2", "banner3"].map(
            (key) =>
              `${process.env.REACT_APP_SAFEHUB_BMS_URL2}/${bannersData[key]}`
          );

          setBanners(fullBannerURLs);
          // Extract and set What We Do and Contact Information
          setWhatWeDo(bannersData.cf_what_we_do);
          setContactInfo(bannersData.cf_contact_info);
        }
        // Fetch sites
        const siteResponse = await fetch(
          `${process.env.REACT_APP_SAFEHUB_BMS_URL}/webservices/getSites/S@f3Hub123@`
        );
        const siteData = await siteResponse.json();
        if (siteData && siteData.length > 0) {
          setSites(siteData);
        }
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    }

    fetchData();
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % banners.length);
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep - 1 + banners.length) % banners.length
    );
  };

  return (
    <Container>
      <Grid container spacing={4} direction="column" alignItems="center">
        <Grid item>
          <img src="/assets/images/logo.svg" alt="Logo" width="150" />
        </Grid>
        {/* Carousel banner using MobileStepper */}
        {banners.length > 0 && (
          <Grid item xs={12}>
            <img
              src={banners[activeStep]}
              alt={`Banner ${activeStep + 1}`}
              style={{ width: "100%", maxHeight: "400px", objectFit: "cover" }}
            />
            <MobileStepper
              variant="dots"
              steps={banners.length}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button size="small" onClick={handleNext}>
                  Next
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack}>
                  Prev
                </Button>
              }
            />
          </Grid>
        )}
        {/* <Grid item>
          <Typography variant="h4" gutterBottom>
            Welcome to the Safe-Hub Portal
          </Typography>
        </Grid> */}

        {/* <Grid item>
          <Typography variant="body1" paragraph>
            Join our programmes and get the best learning experience.
          </Typography>
        </Grid> */}

        <Grid item container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/register"
            >
              Sign Up Now
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              component={Link}
              to="/login"
            >
              Already Registered? Login
            </Button>
          </Grid>
        </Grid>

        <Grid item container spacing={2} justifyContent="space-between">
          <Grid item xs={12} md={5}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  What We Do
                </Typography>
                <Typography>
                  {whatWeDo ||
                    "Provide a brief description of the services or products offered by Safe-Hub. You can elaborate on the purpose and goals of the portal here."}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={5}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Contact Information
                </Typography>
                <Typography>
                  {contactInfo ||
                    "Provide the necessary contact details for Safe-Hub, such as an email address, phone number, or physical address."}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* New Section for Our Sites */}
        <Grid item container spacing={2} justifyContent="space-between">
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Our Sites
            </Typography>
          </Grid>
          {sites.map((site, index) => (
            <Grid item xs={12} md={5} key={index}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {site.name}
                  </Typography>
                  <Typography><b>Tel:</b> {site.cf_sitecontactnumber}</Typography>
                  <Typography><b>Address:</b> {site.address}</Typography>
                  
                  {site.cf_wtw_link && (
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginTop: "10px" }}
                      onClick={() => window.open(site.cf_wtw_link, "_blank")}
                    >
                      Map (W3W)
                    </Button>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid item container justifyContent="center">
          <Typography variant="body2">Safe-Hub Portal © 2023</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LandingPage;
