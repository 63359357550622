import React, { useState } from "react";
// import React, { useState, useEffect } from "react";
// import axios from "axios"; // Import axios for making HTTP requests
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "./Menu"; // Importing Menu Component
import Cookies from "js-cookie";

const Programmes = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  // const [programmes, setProgrammes] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  // const [selectedProgrammeId, setSelectedProgrammeId] = useState(null);

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleLogout = () => {
    Cookies.remove("contactid");
    // Redirects user to landing page or login page
  };

  // const handleRegisterClick = (programmeId, assignedTo) => {
  //   setSelectedProgrammeId(programmeId);
  //   setOpenDialog(true);
  // };

  // const handleConfirmRegistration = async () => {
  //   try {
  //     const payload = {
  //       module: "ProgrammeRegistrations",
  //       data: {
  //         name: "title auto created",
  //         cf_programmeofferings_id: selectedProgrammeId,
  //         cf_contacts_id: contactId,
  //         cf_registrationstatus: "Interested",
  //         assigned_user_id: programmes.find(
  //           (prog) => prog.id === selectedProgrammeId
  //         )?.assigned_to,
  //       },
  //     };

  //     const response = await axios.post(
  //       `${process.env.REACT_APP_EXPRESS_SERVER_URL}/create`,
  //       payload
  //     );

  //     console.log("Registration Response:", response.data);

  //     // Update the programmes state to mark the programme as registered
  //     setProgrammes(
  //       programmes.map((prog) =>
  //         prog.id === selectedProgrammeId ? { ...prog, registered: true } : prog
  //       )
  //     );

  //     setOpenDialog(false);
  //   } catch (error) {
  //     console.error("Error registering for programme:", error);
  //     // Handle error
  //   }
  // };

  // const contactId = Cookies.get("contactid");

  // useEffect(() => {
  //   const fetchProgrammes = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_SAFEHUB_BMS_URL}/webservices/getAvailableProgrammes/S@f3Hub123@/${contactId}`
  //       );
  //       setProgrammes(
  //         response.data.map((programme) => ({
  //           id: programme.programmeoffering_id,
  //           name: programme.cf_portal_label,
  //           description: programme.cf_portal_desc,
  //           startDate: programme.cf_startdate,
  //           endDate: programme.cf_enddate,
  //           registered: programme.cf_registrationstatus,
  //           assigned_to: programme.assigned_to,
  //         }))
  //       );
  //     } catch (error) {
  //       console.error("Error fetching programmes:", error);
  //       // Handle error
  //     }
  //   };

  //   fetchProgrammes(); // Invoke the async function to fetch programmes
  // }, [contactId]);

  return (
    <div>
      {/* AppBar */}
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Programmes
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <Menu
        openDrawer={openDrawer}
        handleDrawerToggle={handleDrawerToggle}
        handleLogout={handleLogout}
      />

      {/* Programme Cards */}
      <Container>
        <br></br>
        <br></br>
        <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h5">Coming Soon!</Typography>
                  <Typography variant="body2">
                  <br></br>
                  Soon we will have the Programmes we have available displayed here. You will be able to register your interest and sign up. <br></br><br></br>Check back soon!
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
        {/*  {programmes.map((programme) => (
            <Grid item xs={12} md={6} key={programme.id}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h5">{programme.name}</Typography>
                  <Typography variant="body2">
                    {programme.description}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "10px" }}
                    onClick={() =>
                      handleRegisterClick(programme.id, programme.assigned_to)
                    }
                    disabled={programme.registered}
                  >
                    {programme.registered ? "Registered" : "Register Interest"}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))} */}
        </Grid>
      </Container>

      {/* Registration Confirmation Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Registration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to register for this programme?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            // onClick={handleConfirmRegistration}
            color="primary"
            variant="contained"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Programmes;
