import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Container, Box, Typography, Button } from "@mui/material";

const SignupSuccess = () => {
  const location = useLocation();
  const pin = location.state?.pin;

  return (
    <Container>
      <Box mt={4} display="flex" flexDirection="column" alignItems="center">
        <img src="/assets/images/logo.svg" alt="Logo" width="150" />
        <Typography variant="h4" gutterBottom>
          Signup Successful!
        </Typography>
        <Typography variant="h5" gutterBottom color="primary">
          Your unique PIN: {pin}
        </Typography>
        <Typography variant="body1" paragraph>
          Make sure you keep the above pin number safe, and lookout for your registration welcome email and sms from Safe-Hub.
        </Typography>

        <Box mt={4}>
          <Button variant="contained" color="primary" component={Link} to="/login">
            Go to Sign In Page
          </Button>
        </Box>
        <Box mt={4}>
          <Typography variant="body2">
            Safe-Hub Portal © 2023
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default SignupSuccess;
