import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import SignUp from './components/SignUp';
import Login from './components/Login';
import SignupSuccess from './components/SignupSuccess.js';
import ForgotPin from './components/ForgotPin';
import Dashboard from './components/Dashboard';
import ProfileDetails from './components/ProfileDetails';
import Programmes from './components/Programmes';
import Support from './components/Support';
// import LoginRedirect from './components/LoginRedirect'; // Import the new component
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';  // Assuming theme.js is in the same directory


//... other components

function App() {
  return (
    <ThemeProvider theme={theme}>
    <Router>
      
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-pin" element={<ForgotPin />} />
        <Route path="/signup-success" element={<SignupSuccess />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile-details" element={<ProfileDetails />} />
        <Route path="/programmes" element={<Programmes />} />
        <Route path="/support" element={<Support />} />
        {/*... other routes*/}
      </Routes>
    </Router>
    </ThemeProvider>
  );
}

export default App;
