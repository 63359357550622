import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Box,
  Grid,
  Paper,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "./Menu"; // Importing Menu Component
import Cookies from "js-cookie"; // Import the js-cookie library

const Dashboard = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const activeStep = 0; // For the stepper
  const [userDetails, setUserDetails] = useState(null);
  const [pendingRegistrations, setPendingRegistrations] = useState([]);
  const [upcomingSessions, setUpcomingSessions] = useState({});
  const navigate = useNavigate();
  const contactId = Cookies.get("contactid");

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleLogout = () => {
    Cookies.remove("contactid");
    navigate("/");
  };

  const steps = [
    {
      label: "Complete Your Profile",
      content:
        "Let's start by completing your profile so we can better match you to our programmes and ensure we have all the information we need to process your future programme registrations",
      buttonLabel: "Update your profile",
      shouldDisplay: userDetails?.cf_profile_updated === "0",
      onClick: () => navigate("/profile-details"),
    },
    {
      label: "Find and Register interest for programs",
      content:
        "Awesome! Now that we have all the information we need, you can navigate to your available programmes at your site and register your interest!",
      buttonLabel: "Show me the programmes",
      onClick: () => navigate("/programmes"),
    },
  ];

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        if (!contactId) {
          console.error("Contact ID is invalid.");
          navigate("/");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_SAFEHUB_BMS_URL}/webservices/getUser/S@f3Hub123@/${contactId}`
        );

        if (response.data && response.data.length > 0) {
          setUserDetails(response.data[0]);
        } else {
          console.error("No valid user details found.");
          navigate("/");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        navigate("/");
      }
    };

    const fetchUserProgrammes = async () => {
      try {
        if (!contactId) {
          console.error("Contact ID is invalid.");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_SAFEHUB_BMS_URL}/webservices/getContactProgrammes/S@f3Hub123@/${contactId}`
        );

        if (response.data && response.data.length > 0) {
          setPendingRegistrations(response.data);
        } else {
          console.log("No programmes found for the user.");
          setPendingRegistrations([]);
        }
      } catch (error) {
        console.error("Error fetching user programmes:", error);
      }
    };

    const fetchUpcomingSessions = async () => {
      try {
        if (!contactId) {
          console.error("Contact ID is invalid.");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_SAFEHUB_BMS_URL}/webservices/getContactSessions/S@f3Hub123@/${contactId}`
        );

        if (response.data && response.data.length > 0) {
          const groupedSessions = {};
          response.data.forEach((session) => {
            if (!groupedSessions[session.cf_portal_label]) {
              groupedSessions[session.cf_portal_label] = [];
            }
            groupedSessions[session.cf_portal_label].push({
              sessionTitle: session.cf_sessiontitle,
              sessionDate: session.cf_session_date,
              sessionTime: session.cf_session_time,
            });
          });
          setUpcomingSessions(groupedSessions);
        } else {
          console.log("No upcoming sessions found for the user.");
          setUpcomingSessions({});
        }
      } catch (error) {
        console.error("Error fetching upcoming sessions:", error);
      }
    };

    if (contactId) {
      fetchUserDetails();
      fetchUserProgrammes();
      fetchUpcomingSessions();
    } else {
      navigate("/");
    }
  }, [contactId, navigate]);

  return (
    <div>
      {/* AppBar */}
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            {userDetails?.firstname} {userDetails?.lastname}
          </Typography>
        </Toolbar>
      </AppBar>
      {/* Drawer */}
      <Menu
        openDrawer={openDrawer}
        handleDrawerToggle={handleDrawerToggle}
        handleLogout={handleLogout}
      />
      {/* Vertical Stepper */}
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        style={{ padding: "20px" }}
      >
        {steps.map((step, index) => {
          if (step.shouldDisplay !== false) {
            if (index === 0 && userDetails?.cf_profile_updated === "0") {
              return (
                <Step key={index}>
                  <StepLabel>{step.label}</StepLabel>
                  <StepContent>
                    <Typography>{step.content}</Typography>
                    <Box mt={4}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={step.onClick}
                      >
                        {step.buttonLabel}
                      </Button>
                    </Box>
                  </StepContent>
                </Step>
              );
            }
            if (
              index === 1 &&
              userDetails?.cf_profile_updated !== "0" &&
              pendingRegistrations.length === 0
            ) {
              return (
                <Step key={index}>
                  <StepLabel>{step.label}</StepLabel>
                  <StepContent>
                    <Typography>{step.content}</Typography>
                    <Box mt={4}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={step.onClick}
                      >
                        {step.buttonLabel}
                      </Button>
                    </Box>
                  </StepContent>
                </Step>
              );
            }
          }
          return null;
        })}
      </Stepper>

      {/* Pending Registrations */}
      {pendingRegistrations.length > 0 && (
        <div>
          <Typography
            variant="h6"
            style={{ marginTop: "0px", marginBottom: "20px" }}
          >
            My Programmes
          </Typography>
          <Grid container spacing={2}>
            {pendingRegistrations.map((registration, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Paper
                  elevation={3}
                  style={{ padding: "10px", marginBottom: "10px" }}
                >
                  <Typography variant="h6">
                    {registration.cf_portal_label}
                  </Typography>
                  <Typography>Date: {registration.cf_startdate}</Typography>
                  <Typography>
                    Status: {registration.cf_registrationstatus}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      {/* My Upcoming Sessions */}
      {Object.entries(upcomingSessions).length > 0 ? (
  <div>
    {/* My Upcoming Sessions */}
    <Typography
      variant="h6"
      style={{ marginTop: "20px", marginBottom: "20px" }}
    >
      My Upcoming Sessions
    </Typography>
    {Object.entries(upcomingSessions).map(([programme, sessions]) => (
      <div key={programme}>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          Programme: {programme}
        </Typography>
        <Grid container spacing={2}>
          {sessions.map((session, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Paper
                elevation={3}
                style={{ padding: "10px", marginBottom: "10px" }}
              >
                <Typography>
                  Session Title: {session.sessionTitle}
                </Typography>
                <Typography>Session Date: {session.sessionDate}</Typography>
                <Typography>Session Time: {session.sessionTime}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>
    ))}
  </div>
) : ''}

    </div>
  );
};

export default Dashboard;
