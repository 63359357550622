import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios"; // Ensure axios is imported
import { Container, Box, Typography, TextField, Button } from "@mui/material";
import Cookies from "js-cookie";  // Import the js-cookie library

const Login = () => {
  const navigate = useNavigate();
  const [pin, setPin] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const handleInputChange = (e) => {
    setPin(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    // Call your endpoint to verify the pin
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SAFEHUB_BMS_URL}/webservices/checkPin/S@f3Hub123@/${pin}`
      );

      if (response.data && response.data.length === 0) {
        // No user found with this pin
        setFeedbackMessage(
          "Invalid PIN. Please try again or use the forgot pin link to retrieve your details."
        );
      } else {
        // localStorage.setItem("contactid", response.data[0].contactid);
        Cookies.set("contactid", response.data[0].contactid);

        navigate("/dashboard");
      }
    } catch (error) {
      setFeedbackMessage("An error occurred. Please try again.");
      console.error("Error checking PIN:", error);
    }
  };

  return (
    <Container>
      <Box mt={4} display="flex" flexDirection="column" alignItems="center">
        <img src="/assets/images/logo.svg" alt="Logo" width="150" />
        <Typography variant="h5" gutterBottom>
          Login to your portal
        </Typography>

        <Typography variant="body1" paragraph>
          Enter your PIN to proceed.
        </Typography>

        <form onSubmit={handleLogin}>
          <Box mt={2} width="100%">
            
            <TextField
              fullWidth
              variant="outlined"
              label="PIN Number"
              value={pin}
              onChange={handleInputChange}
              required
            />
          </Box>
          <Box
            mt={2}
            display="flex"
            justifyContent="space-between"
            width="100%"
          >
            <Button
              variant="outlined"
              color="secondary"
              component={Link}
              to="/"
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Login
            </Button>
          </Box>
          <Box
            mt={2}
            display="flex"
            justifyContent="space-between"
            width="100%"
          ></Box>
          <Box mt={2}>
            <Link to="/forgot-pin">
              <p>I Don’t know my pin</p>
            </Link>
          </Box>
          {feedbackMessage && (
            <Box mt={2}>
              <Typography variant="body2" color="error">
                {feedbackMessage}
              </Typography>
            </Box>
          )}
        </form>

        <Box mt={4}>
          <Typography variant="body2">Safe-Hub Portal © 2023</Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
