// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        h1: {
            fontFamily: 'GT Pressura Mono Bold',
        },
        h2: {
            fontFamily: 'GT Pressura Mono Bold',
        },
        // ... add other heading types as needed
        body1: {
            fontFamily: 'Source Sans Pro',
        },
        body2: {
            fontFamily: 'Source Sans Pro',
        },
        // ... any other typography variants
    },
    palette: {
        primary: {
            main: '#46484A',
        },
        secondary: {
            main: '#EC4A32',
        },
        success: {
            main: '#62B888',
        },
        warning: {
            main: '#ffc409',
        },
        error: {
            main: '#eb445a',
        },
        // ... you can add or override more colors as needed
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                'html, body': {
                    backgroundImage: 'url(/assets/images/bg.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                },
            },
        },
    },    
});

export default theme;
