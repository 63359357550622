import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Button,
  TextField,
  Container,
  Typography,
  Box,
  CircularProgress
} from "@mui/material";

const ForgotPin = () => {
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [sendingSms, setSendingSms] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleForgotPin = async (e) => {
    e.preventDefault();
    setLoading(true);

    const queryMobile = mobile || "dummyMobile";
    const queryEmail = email || "dummyEmail";

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SAFEHUB_BMS_URL}/webservices/forgotPin/S@f3Hub123@/${queryMobile}/${queryEmail}`
      );
      const data = response.data;

      if (data && data.length > 0) {
        setUserDetails(data[0]);
        setFeedbackMessage("");
      } else {
        setFeedbackMessage("No user found with the provided details. Try again, or Sign-up for an account.");
      }
    } catch (error) {
      setFeedbackMessage("An error occurred. Please try again.");
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  const obfuscateEmail = (email) => {
    const [user, domain] = email.split("@");
    return `${user[0]}****@${domain}`;
  };

  const obfuscateMobile = (mobile) => {
    return `${mobile.slice(0, 2)}****${mobile.slice(-2)}`;
  };

  const sendPin = async (action) => {
    if (action === "email") {
      setSendingEmail(true);
    } else {
      setSendingSms(true);
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SAFEHUB_BMS_URL}/sendpin.php?contactid=${userDetails.contactid}&action=${action}`
      );
      const data = response.data;

      if (data === "success") {
        setFeedbackMessage(`PIN sent successfully via ${action.toUpperCase()}.`);
        setSuccess(true);
      } else {
        setFeedbackMessage(`Failed to send PIN via ${action.toUpperCase()}.\n ${data}`);
      }
    } catch (error) {
      setFeedbackMessage(`An error occurred while sending PIN via ${action.toUpperCase()}.`);
      console.error(`Error sending PIN via ${action}:`, error);
    } finally {
      if (action === "email") {
        setSendingEmail(false);
      } else {
        setSendingSms(false);
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box mt={8} mb={4} textAlign="center">
        <img src="/assets/images/logo.svg" alt="Logo" className="small-logo" />
        <Typography variant="h5">Forgot PIN</Typography>
        <Typography variant="body1">
          Please enter your last known mobile number and/or email below.
        </Typography>
      </Box>

      {!userDetails ? (
        <form onSubmit={handleForgotPin}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="mobile"
            label="Mobile Number"
            type="tel"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Box mt={2}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Retrieve PIN"}
            </Button>
          </Box>
          <Box mt={2}>
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              color="primary"
              component={Link}
              to="/register"
            >
              Sign Up Now
            </Button>
          </Box>
          <Box mt={2}>
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              color="secondary"
              component={Link}
              to="/login"
            >
              Back
            </Button>
          </Box>
        </form>
      ) : (
        <Box mt={2} textAlign="center">
          <Typography>
            Hello, {userDetails.firstname} {userDetails.lastname}!
          </Typography>
          <Box mt={2}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => sendPin('email')}
              disabled={sendingEmail || sendingSms}
            >
              {sendingEmail ? <CircularProgress size={24} /> : `Email PIN to ${obfuscateEmail(userDetails.email)}`}
            </Button>
            <Box mt={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => sendPin('sms')}
                disabled={sendingEmail || sendingSms}
              >
                {sendingSms ? <CircularProgress size={24} /> : `SMS PIN to ${obfuscateMobile(userDetails.mobile)}`}
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      {feedbackMessage && (
        <Box mt={2} textAlign="center">
          <Typography variant="body2" color="error">{feedbackMessage}</Typography>
        </Box>
      )}

      {success && (
        <Box mt={2} textAlign="center">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            component={Link}
            to="/login"
          >
            Return to Login Screen
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default ForgotPin;